<template>
    <div>
        <BT-Fast-Start-Banner />
        <BT-Blade-Item
            bladeName="product-group"
            :bladesData="bladesData"
            navigation="product-groups"
            :trackIgnore="['products']">
            <template v-slot="{ item, data }">

                <BT-Field-String
                    v-model="item.groupName"
                    label="Group Name"
                    :isEditing="data.isEditing || data.isNew" />
                
                <BT-Sidebar-List
                    v-if="!data.isNew"
                    inline
                    label="Customers"
                    manyNavigation="customers"
                    filterProp="ProductOfferingGroupID"
                    :singleID="item.id"
                    :itemProperties="['ID','Buyer','ProductOfferingGroupID']"
                    singleProp="productOfferingGroupID"
                    manyText="buyer.companyName"
                    :searchProps="['buyer.companyName']"
                    small />
                    
                <v-expansion-panels 
                    v-if="!data.isNew"
                    v-model="panelV"
                    height="60vh">

                    <BT-Blade-Expansion-Links
                        height="60vh"
                        :itemProperties="['ID','ProductName']"
                        label="Products"
                        linkGetURL="/get/Links?GroupID={id}"
                        linkNavigation="product-groups"
                        manyText="productName"
                        manyProp="productID"
                        manyToMany
                        manyNavigation="products"
                        :searchProps="['productName']"
                        :singleID="item.id"
                        singleProp="productOfferingGroupID"
                        startEditing
                        startOpen />
                    
                </v-expansion-panels>
            </template>
        </BT-Blade-Item>
    </div>
</template>

<script>
export default {
    name: 'Product-Group',
    components: {
        BTFastStartBanner: () => import('~components/BT-Fast-Start-Banner.vue'),
        BTSidebarList: () => import('~components/BT-Sidebar-List.vue'),
        BTBladeExpansionLinks: () => import('~components/BT-Blade-Expansion-Links.vue')
    },
    data: function() {
        return {
            panelV: 0
        }
    },
    props: {
        bladesData: null
    }
}
</script>